<template>
	<div class="full-height flex-column justify-space-between">
		<div class="header">
			<div class="cartel_top">
				<div class="top top_mafi f-left">
					<div class="util">
						<button
							@click="goBack"
							class="arrow_white"
						>
							<i class="icon-arrow-left"></i>
						</button>
					</div>
					<div class="page_tit_area">
						<h2 class="page_tit"> {{ program.title }} </h2>
					</div>
				</div>
			</div>
		</div>
		<!-- //고정해더 -->
		<div
			class="section_wrap pt-80 size-px-14"
		>
			<div class="subindex_head">
				<div class="container">
					<div class="subindex_box_1 justify-space-between">

						<button
							@click="onTab('in')"
							class="flex-1 btn_l mr-10"
							:class="tab_type == 'in' ? 'btn_fill_blue' : 'btn_fill_gray'"
						>구독중</button>

						<button
							@click="onTab('out')"
							class="flex-1 btn_l "
							:class="tab_type == 'out' ? 'btn_fill_blue' : 'btn_fill_gray'"
						>구독 종료</button>
					</div>
					<div class="subindex_box_1">
						<ul
							class="mt-30"
							v-if="items_member.length > 0"
						>
							<li
								v-for="(item, index) in items_member"
								:key="'item_' + index"
								class="justify-space-between items-center mb-30"
								@click="toDetail(item)"
							>
								<div class="img-box-100 mr-10 radius-10"><img :src="item.subscrp_plan_img_url" @error="$bus.$emit('onErrorImage', $event)" class="object-cover"/></div>
								<div class="flex-1 ">
									<div class="font-weight-700 size-px-16">{{ item.cartl_name }}</div>
									<div class="mtb-5">{{ item.subscrp_plan_name }}</div>
									<div class="color-gray size-px-13">{{ item.subscrp_enddate.slice(0, 10) }} {{ $language.cartel_new.title_finish }} <!-- 종료 --></div>
								</div>
							</li>
						</ul>
						<Empty
							v-else
							class=""
						></Empty>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Empty from "@/view/Layout/Empty";
export default {
	name: 'MypageSubscribeList'
	, components: {Empty}
	, props: ['user']
	, data: function(){
		return {

			program: {
				name: this.$language.notice.add
				, title: '구독 통합 내역'
				, not_footer: true
				, not_header: true
				, type: 'cartel_sub'

			}
			, item_cartel: {

			}
			, item_member: {

			}
			, items_member: [
				{
					cartl_name: '닉네임'
					, subscrp_plan_name: '결제유형코드'
					, subscrp_enddate: '2023.05.31 종료'
					, subscrp_plan_img_url: ''
					, cartl_number: '31238667920b24d'
				}
				, {
					cartl_name: '1'
					, subscrp_plan_name: 'MAF'
					, subscrp_enddate: 'BC00100002'
					, coin_token_code: 'BC00200001'
					, subscrp_plan_img_url: ''
					, cartl_number: '31238667920b24d'
				}
				, {
					cartl_name: '1'
					, subscrp_plan_name: 'MAF'
					, subscrp_enddate: 'BC00100002'
					, coin_token_code: 'BC00200002'
					, subscrp_plan_img_url: ''
					, cartl_number: '31238667920b24d'
				}
			]
			, tab_type: 'in'
		}
	}
	, computed: {

	}
	, methods: {
		getInMember: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_mypage_subscribe_in_list
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})

				if(result.success){
					this.items_member = result.data.subscrp_unify_subscrp_particulars_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}


		, getOutMember: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_mypage_subscribe_out_list
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})

				if(result.success){
					this.items_member = result.data.subscrp_unify_subscrp_end_particulars_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, goBack: function(){
			this.$bus.$emit('goBack')
		}
		, onTab: function(type){
			this.tab_type = type
		}
		, toDetail: function(item){
			this.$bus.$emit('to', { name: 'CartelSubscribeList', params: { idx: item.cartl_number}})
		}
	}
	, async created() {
		this.$bus.$emit('onLoad', this.program)
		await this.getInMember()
	}
	, watch: {
		tab_type: {
			handler: function(call){
				if(call == 'in'){
					this.getInMember()
				}else{
					this.getOutMember()
				}
			}
		}
	}

}
</script>